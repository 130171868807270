<template>
    <v-app>
        <v-main>
            <v-row no-gutters class="fill-height">
                <v-col
                    cols="12"
                    md="6"
                    class="d-none d-md-flex justify-center align-center"
                >
                    <v-img
                        max-height="70"
                        max-width="280"
                        src="../assets/PROJECT_AGENDA.png"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center grey lighten-4"
                >
                    <router-view />
                </v-col>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'Auth',
}
</script>
